// React requires
import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";
import SCLink from "../typography/SC-Link";

type propType = {
  icon?: any;
  to: any;
  variant?: string;
  children: any;
};

const TextButton = (props: propType) => {
  return (
    <SCTextButton to={props.to}>
      <SCLink>{props.children}</SCLink>
      <img src={props.icon} alt="icon"/>
    </SCTextButton>
  );
};

const SCTextButton = styled(Link)`
  background-color: transparent;
  color: ${colors.primary};
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    opacity: .7;
  }
  
  img {
    background-size: 24px;
    width: 22px;
    height: 54px;
    margin-left: 24px;

    @media ${device.lg} {
      width: 16px;
      height: 28px;
      margin-left: 12px;
    }

    @media ${device.sm} {
      width: 12px;
      margin-left: 5px;
      height: 20px;
    }
  }
`;

export default TextButton;
