const routeVariants = {
    in: {
        opacity: 1,
        // y: 0,
        // scale: 1,
    },
    out: {
        opacity: 0,
        // y: '-100vw',
        // scale: 0.8
    }
};
const routeTransitions = {
    // duration: 1
}


export {
    routeVariants,
    routeTransitions
};
