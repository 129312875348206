// React requires
import React from "react";

import styled from "styled-components";
import {device} from "../../assets/styles/variables/breakpoints";

// React assets

type propType = {
    image?: string;
    imageMobile?: string;
    children: any;
    backgroundPosition?: number
    variant?: string;
};

const Hero = (props: propType) => {
    return <SCHero {...props}>{props.children}</SCHero>;
};

const SCHero = styled.div`
  background-image: ${(props: propType) =>
    props.variant === "light" &&
    "linear-gradient(0deg, rgba(162,162,162,0.001), rgba(255,255,255,0.67)),"}
url(${(props: propType) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: ${(props: propType) => props.backgroundPosition}px;
  padding-top: 110px;
  padding-bottom: 60px;
  background-size: cover;
  min-height: 640px;
  max-height: 1080px;
  
  @media ${device.sm} {
   background-image: ${(props: propType) =>
    props.variant === "light" &&
    "linear-gradient(0deg, rgba(162,162,162,0.001), rgba(255,255,255,0.67)),"}
     url(${(props: propType) => props.imageMobile});
  }

  .heading,
  .subheading {
    position: relative;
  }

  @media ${device.xl} {
    height: auto;
    max-height: 800px;
  }

  @media ${device.lg} {
    max-height: 800px;
  }

  @media ${device.md} {
    padding: 0;
  }
  @media ${device.sm} {
  }
`;

export default Hero;
