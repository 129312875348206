import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
    color?: any;
    lineColor?: any;
};

export default styled.h2<propType>`
margin: 24px 0 30px;
${typography.subtitle}
color: ${(props: propType) => props.color || colors.primary};
text-align: center;
position: relative;

&:after {
content: "";
background-color: ${(props: propType) =>
    props.lineColor ? props.lineColor : colors.secondary}; 
width: 82px;
height: 3px;
display: block;
margin: 5px auto 0;

  @media ${device.md} {
    ${typography.subtitle.tablet}
    margin: 0;
    height: 2px;
  }
  @media ${device.sm} {
    position: absolute;
    width: 100%;
    display: none;
  }
}

@media ${device.lg} {
  ${typography.subtitle.desktop}
}

@media ${device.md} {
    ${typography.subtitle.tablet}
    margin: 24px 0;
    text-align: left;
}

@media ${device.sm}  {
    ${typography.subtitle.tablet}
    display: inline-block;
}

@media ${device.xs}  {
    ${typography.subtitle.mobile}
}
`;
