import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import {device} from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";
import {space} from "../../../assets/styles/variables/spaces";

type propType = {
    color?: any;
    style?: any
    alignment?: string
    italic?: any
    marginBottom?: Number
};

export default styled.p<propType>`
  color: ${(props: propType) => props.color || colors.primary};
  ${typography.body_small};
  text-align: ${(props: propType) => props.alignment && props.alignment};
  margin: 0;
  ${(props: propType) => props.italic ? 'font-style: italic' : ''};
  ${(props: propType) => props.marginBottom ? `margin-bottom: ${space(props.marginBottom)}` : ''};
   

  strong {
    font-weight: 600;
  }
  
   // @media ${device.lg} {
  //   ${typography.body_small.desktop};
  // } 

  @media ${device.md} {
    ${typography.body_small.tablet};
  }

  @media ${device.sm} {
    ${typography.body_small.mobile};
  }
`;
