import styled from "styled-components";
import {device} from "../../assets/styles/variables/breakpoints";
import {space} from "../../assets/styles/variables/spaces";

export const SCMiddle = styled.div`
  padding-top: 90px;
  

  h2 {
    margin: ${space(7)} 0 ${space(4)};
  }
 a {
   float:right;
   }
   div.body-content{
   margin:15px 0;
   display: inline-block;
   }
  p {
  
    &:nth-child(2) {
      text-align: center;
      max-width: 880px;
      margin: 0 auto 32px;

      @media ${device.md} {
        text-align: left;
      }
    }
  }

  a {
    display: flex;
    justify-content: flex-end;
  }

  .image-wrapper {
    width: 100%;
    position: relative;
    height: 450px;

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @media ${device.lg} {
      height: 300px;
    }
  }

  @media ${device.md} {
    padding-top: 0;
  }
`;

export const Careers = styled.div`
  padding-top: ${space(6)};
  @media ${device.sm} {
    padding-top: ${space(2)};
  }
`;

export const LearnMore = styled.div`
  padding-top: ${space(6)};
  @media ${device.sm} {
    padding-top: ${space(2)};
  }
`;

const HomepageLinks = styled.div`
  @media ${device.sm} {
    flex-wrap: wrap;
    justify-content: space-between;

    a {
      margin-left: 12px;
    }
  }
`;

export const CareersLink = styled(HomepageLinks)``;

export const AboutLink = styled(HomepageLinks)``;

export const Paragraph = styled.div`
  padding-bottom: 24px;
`;
