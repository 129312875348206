import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
    color?: any;
};

export default styled.p`
  ${typography.link_primary};
  color: ${colors.primary};
  display: flex;
  align-items: center; 

  @media ${device.lg} {
    ${typography.link_primary.tablet};
  }


  @media ${device.sm} {
    ${typography.link_primary.mobile};
    display: inline-block;
  }
`;
