// React requires
import React from "react";
// React libraries
import {Col, Container, Row} from "react-grid-system";
import {Picture} from 'react-responsive-picture';
// React components
import {AboutLink, Careers, CareersLink, LearnMore, Paragraph, SCMiddle} from "./SC-Middle";
import TextButton from "../../components/UI/Buttons/TextButton";
// React typography components
import SCSubtitle from "../../components/UI/typography/SC-Subtitle";
import SCBodySmall from "../../components/UI/typography/SC-BodySmall";
import SCBodyLarge from "../../components/UI/typography/SC-BodyLarge";
// React assets
import SocietyImage from "../../assets/images/homepage/society-cover/image.jpg";
import SocietyImage2x from "../../assets/images/homepage/society-cover/image@2x.jpg";
import SocietyImageMobile from "../../assets/images/homepage/society-cover/image-mobile.jpg";
import SocietyImageMobile2x from "../../assets/images/homepage/society-cover/image-mobile@2x.jpg";

import ArrowRight from "../../assets/icons/arrows-right.svg";
import YTCover from "../../assets/images/homepage/youtube-cover/image.jpg";
import YTCover2x from "../../assets/images/homepage/youtube-cover/image@2x.jpg";
import YTCoverMobile from "../../assets/images/homepage/youtube-cover/image-mobile.jpg";
import YTCoverMobile2x from "../../assets/images/homepage/youtube-cover/image-mobile@2x.jpg";
import colors from "../../assets/styles/variables/colors";

const Middle = (props: any) => {
    const {page} = props;
    return (
        <>
            <SCMiddle>
                <Container>
                    <Paragraph>
                        <SCSubtitle color={colors.primary}>A Trusted Partner</SCSubtitle>
                        <SCBodySmall>
                            Engineering design, project management, coordination, inspection,
                            testing, and surveying. With over 30 years of industry expertise,
                            clients rely on our reputation for quality and dedication to
                            facilitate projects of any scale.
                        </SCBodySmall>
                    </Paragraph>
                </Container>

                <LearnMore>
                    <Container>
                        <Row align="center" justify="center">
                            <Col xl={8}>
                                <Picture
                                    alt="Career"
                                    sources = {[
                                        {
                                            srcSet: `${YTCoverMobile}, ${YTCoverMobile2x} 2x`,
                                            media: "(max-width: 420px)",
                                        },
                                        {
                                            srcSet: `${YTCover} 1x, ${YTCover2x} 2x`,
                                        },
                                        {
                                            srcSet: YTCover,
                                            type: "image/jpg",
                                        }
                                    ]}
                                />
                                {/*<VideoFullScreen cover={coverVideo}/>*/}
                                <AboutLink>
                                    <SCBodyLarge>
                                        <div className="body-content">Learn more about our story & what we do.</div>
                                        <TextButton to={page.about.path} icon={ArrowRight}>
                                            About
                                        </TextButton>
                                    </SCBodyLarge>
                                </AboutLink>
                            </Col>
                        </Row>
                    </Container>
                </LearnMore>
            </SCMiddle>
            <SCMiddle>
                <Container>
                    <Paragraph>
                        <SCSubtitle color={colors.primary}>Contribute To Society</SCSubtitle>
                        <SCBodySmall>
                            We are a team of passionate leaders, designers, and collaborators
                            invested in our communities and your long-term success. With a
                            path tailored to your strengths and interests, you can build a
                            career that thrives towards your aspirations.
                        </SCBodySmall>
                    </Paragraph>
                </Container>

                <Careers>
                    <Container>
                        <Row align="center" justify="center">
                            <Col xl={8}>
                                <div className="image-wrapper">
                                    <Picture
                                        alt="Career"
                                        sources = {[
                                            {
                                                srcSet: `${SocietyImageMobile}, ${SocietyImageMobile2x} 2x`,
                                                media: "(max-width: 420px)",
                                            },
                                            {
                                                srcSet: `${SocietyImage} 1x, ${SocietyImage2x} 2x`,
                                            },
                                            {
                                                srcSet: SocietyImage,
                                                type: "image/jpg",
                                            }
                                        ]}
                                    />
                                </div>

                                <CareersLink>
                                    <SCBodyLarge>
                                        <div className="body-content">Find your path & learn about who we are.</div>
                                        <TextButton to={page.career.path} icon={ArrowRight}>
                                            Careers
                                        </TextButton>
                                    </SCBodyLarge>

                                </CareersLink>
                            </Col>
                        </Row>
                    </Container>
                </Careers>
            </SCMiddle>
        </>
    );
};

export default Middle;
