import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
  color?: any;
};

export default styled.h4`
  ${typography.small_subtitle};
  margin-bottom: 0;
  color: ${(props: propType) => props.color || colors.primary};
  transition: 1s ease;

  @media ${device.md} {
    ${typography.small_subtitle.tablet};
  }

  @media ${device.sm} {
    ${typography.small_subtitle.mobile};
  }
`;
