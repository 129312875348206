import styled from "styled-components";
import {device} from "../../assets/styles/variables/breakpoints";
import background from "../../assets/images/homepage/background/image.jpg";
import backgroundMobile from "../../assets/images/homepage/background/image-mobile.jpg";
import strings from "../../assets/images/homepage/background/strings.svg";
import logoTransparent from "../../assets/images/homepage/bg-logo/image.png";

export const SCHome = styled.div`
  overflow-x: hidden;
`;

export const HeroLargeText = styled.div`
  padding-top: 150px;
  text-align: right;

  @media ${device.md} {
    padding-top: 170px;
    text-align: center;
  }
  @media ${device.sm} {
    padding-top: 160px;
    span {
      float: left;
    }
  }
`;

export const HeroSmallText = styled.div`
  padding-top: 220px;
  text-align: left;
  line-height: 1.2;

  @media ${device.md} {
    padding-top: 200px;
  }
`;

export const SCSkyBackground = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 100%;
  max-width: 100%;

  @media ${device.sm} {
    background-image: url(${backgroundMobile});
    background-position-y: -100px;
  }
`;

export const SCStrings = styled.div`
  position: absolute;
  top: 400px;
  display: block;
  width: 100%;
  left: 0;
  right: 0;
  height: 400px;
  background-image: url(${strings});
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;

  @media ${device.md} {
    top: 250px;
  }

  @media ${device.md} {
    height: 200px;
    top: 380px;
  }
`;

export const SCTransparentLogo = styled.div`
  width: 100%;
  height: 1000px;
  background-image: url(${logoTransparent});
  top: 0;
  position: absolute;
  max-width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${device.lg} {
    background-size: cover;
  }

  @media ${device.md} {
    background-size: 800px;
  }
`;
