// React requires
import React from "react";
import styled from "styled-components";

//Assets
import illustration from "../../assets/images/homepage/illustration/image.svg";
import { device } from "../../assets/styles/variables/breakpoints";

type propType = {
  transform?: number;
};

const Middle = (props: propType) => {
  return (
    <SCIllustrationMobile transform={props.transform}>
      <img src={illustration} className="illsutration-mobile" alt="NBM Illustration" />
    </SCIllustrationMobile>
  );
};

const SCIllustrationMobile = styled.div`
  z-index: 9;

  .illsutration-mobile {
    position: relative;
    bottom: 0;
    left: calc(100vw - 1450px);
    z-index: 0;
    transition: 1s ease;
    width: 2000px;
    height: 300px;
    margin-top: -220px;

    @media ${device.md} {
      left: 0;
      margin-top: -60px;
      width: 3000px;
      ${(props: propType) =>
        props.transform && `transform: translateX(${props.transform}px)`};
    }

    @media ${device.sm} {
      width: auto;
      ${(props: propType) =>
        props.transform && `transform: translateX(${props.transform}px)`};
    }
  }
`;

export default Middle;
