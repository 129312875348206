import styled from "styled-components";
import illustration from "../../assets/images/homepage/illustration/image.svg";
import arrowRight from "../../assets/icons/arrows-right.svg";
import circle from "../../assets/icons/circle.svg";
import { device } from "../../assets/styles/variables/breakpoints";
import { space } from "../../assets/styles/variables/spaces";
import colors from "../../assets/styles/variables/colors";

export default styled.div`
  margin-top: ${space(7)};
  padding-top: ${space(6)};

  position: relative;

  .services-container {
    z-index: 1;
  }

  .clickableService {
    text-decoration: none;
    color: ${colors.primary};
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    p {
      margin: 0 10px 0 0;
      @media ${device.xl} {
        margin: 0;
      }
    }

    &:after {
      margin-left: 10px;
      content: "";
      position: absolute;
      right: 0;
      background-image: url(${arrowRight});
      height: 32px;
      width: 18px;
      background-repeat: no-repeat;
      background-size: 18px;
      display: block;
    }

    @media ${device.xl} {
      &:after {
      }
    }
    @media ${device.md} {
      &:after {
        display: none;
      }
    }
  }

  .service {
    z-index: 0;
    cursor: pointer;

    &:nth-child(1) {
      margin-top: 70px;
    }

    &:nth-child(2) {
      margin-top: -10px;
    }

    &:nth-child(3) {
      margin-top: 120px;
    }

    &:nth-child(5) {
      margin-top: 100px;
    }

    @media ${device.md} {
      .clickableService,
      h4 {
        padding-left: ${space(12)};
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5) {
        margin-top: 0;
      }
    }

    @media ${device.md} {
      .clickableService,
      h4 {
        padding-left: ${space(10)};
      }
    }
  }

  h3 {
    text-align: center;
    margin-bottom: 100px;
    margin-bottom: 24px;
  }

  .line-circle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: ${space(10)} 0 ${space(16)};
    height: 230px;
    width: 100%;

    &.active {
          &:before {
          height: 84px !important;
      }
    }

    &:before {
      content: "";
      height: 184px;
      width: 4px;
      display: block;
      background-color: ${colors.secondary};
      transition: 1s ease;
    }

    &:after {
      content: "";
      background-image: url(${circle});
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: 24px;
      display: block;
      transition: 1s ease;
    }

    &.active {
      &:after {
      width: 30px;
      height: 30px;
      background-size: 29px;
    }
    }

    &:nth-of-type(1) {
      &:before {
        height: 148px;
      }
    }

    &:nth-of-type(2) {
      &:before {
        height: 204px;
      }
    }

    &:nth-of-type(4) {
      &:before {
        height: 314px;
      }
    }

    @media ${device.md} {
      padding: 0;
      transform: rotate(-90deg);
      position: absolute;
      left: -80px;
      top: -84px;
      width: auto;
      height: auto;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        &:before {
          height: 220px;
        }
      }
    }

    @media ${device.md} {
      top: -86px;

      &:after {
        width: 20px;
        height: 20px;
        background-size: 20px;
      }
    }
  }

  .illustration {
    background-image: url(${illustration});
    background-repeat: repeat-x;
    background-position: bottom;
    width: 100%;
    height: 430px;
    position: absolute;
    bottom: 0;
    z-index: 0;
    background-size: cover;

    @media ${device.lg} {
      background-size: 3000px;
    }
  }
`;
