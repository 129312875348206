// React requires
import React, {useState, useRef} from "react";

// React libraries
import {Container, Row, Col, Visible} from "react-grid-system";

// React components
import SCServices from "./SC-Services";
import IllustrationMobile from "./IllustrationMobile";

// React typography components
import SCSubheading from "../../components/UI/typography/SC-Subheading";
import SCSmallSubtitle from "../../components/UI/typography/SC-SmallSubtitle";
import SCSmall from "../../components/UI/typography/SC-Small";

// React assets
import colors from "../../assets/styles/variables/colors";
import {useHistory} from "react-router";

//const history = useHistory();

const Services = (props: any) => {
    const {page} = props;
    const [transform, setTranform] = useState();
    const [activeIndex, setActiveIndex] = useState(null);
    const illustration = useRef();
    let history = useHistory();

    const setActiveClass: any = (index: any) => {
        setActiveIndex(index);
    };

    const handleHoverChange = (index: any) => {
        setActiveClass(index);
    };

    const handleChangeClick: any = (path: string, hash: string) => {
        history.push(path + '#' + hash);
    };

    const scrollBottom = () => {
        //@ts-ignore
        illustration.current.scrollIntoView({block: "end", behavior: "smooth"});
    };

    const handleChangeTouch: any = (e: any, transform: number, path: string) => {
        e.preventDefault();

        if (window.innerWidth >= 769) {
            handleChangeClick(path);
        } else {
            setTranform(transform);
            scrollBottom();
        }
    };

    return (
        <SCServices>
            <Container className="services-container">
                <SCSubheading color={colors.primary}>
                    Complex solutions, simplified.
                </SCSubheading>

                <Row justify="between">
                    <Col xl={2} lg={2} md={2} sm={12} className="service">
                        <SCSmallSubtitle onClick={() => handleChangeClick(page.path, 'electrical-distribution')}
                                         color={activeIndex === 1 ? colors.secondary : ""}>
                            Electrical Distribution
                        </SCSmallSubtitle>
                        <div
                            className="clickableService"
                            onMouseEnter={() => handleHoverChange(1)}
                            onMouseLeave={() => handleHoverChange(null)}
                            onClick={() => handleChangeClick(page.path, 'electrical-distribution')}
                            onTouchEnd={(e) => handleChangeTouch(e, -800, page.path)}
                        >
                            <SCSmall>
                                Aerial & Underground
                                <br/>
                                Stations
                                <br/>
                                Structural & Civil
                            </SCSmall>
                        </div>
                        <div
                            className={`${activeIndex === 1 ? "active" : ""} line-circle`}
                        />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} className="service">
                        <SCSmallSubtitle onClick={() => handleChangeClick(page.path, 'field-services')}
                                         color={activeIndex === 2 ? colors.secondary : ""}>
                            Field Services
                        </SCSmallSubtitle>
                        <div
                            className="clickableService"
                            onMouseEnter={() => handleHoverChange(2)}
                            onMouseLeave={() => handleHoverChange(null)}
                            onClick={() => handleChangeClick(page.path, 'field-services')}
                            onTouchEnd={(e) => handleChangeTouch(e, -1000, page.path)}
                        >
                            <SCSmall>
                                Inspections & Testing
                                <br/>
                                Surveying
                                <br/>
                                Construction PM
                            </SCSmall>
                            <div
                                className={`${activeIndex === 2 ? "active" : ""} line-circle`}
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} className="service">
                        <SCSmallSubtitle onClick={() => handleChangeClick(page.path, 'large-scale-transit')}
                                         color={activeIndex === 3 ? colors.secondary : ""}>
                            Large Scale Transit{" "}
                        </SCSmallSubtitle>
                        <div
                            className="clickableService"
                            onMouseEnter={() => handleHoverChange(3)}
                            onMouseLeave={() => handleHoverChange(null)}
                            onClick={() => handleChangeClick(page.path, 'large-scale-transit')}
                            onTouchEnd={(e) => handleChangeTouch(e, -1300, page.path)}
                        >
                            <SCSmall>
                                LRT & BRT
                                <br/>
                                Heavy Rail Corridors
                                <br/>
                                Highway Expansions
                            </SCSmall>
                            <div
                                className={`${activeIndex === 3 ? "active" : ""} line-circle`}
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} className="service">
                        <SCSmallSubtitle onClick={() => handleChangeClick(page.path, 'alternative-energy')}
                                         color={activeIndex === 4 ? colors.secondary : ""}>
                            Alternative Energy{" "}
                        </SCSmallSubtitle>
                        <div
                            className="clickableService"
                            onMouseEnter={() => handleHoverChange(4)}
                            onMouseLeave={() => handleHoverChange(null)}
                            onClick={() => handleChangeClick(page.path, 'alternative-energy')}
                            onTouchEnd={(e) => handleChangeTouch(e, -1500, page.path)}
                        >
                            <SCSmall>
                                Electric Car Chargers
                                <br/>
                                Battery Storage
                                <br/>
                                Smart Grid Solutions
                            </SCSmall>
                            <div
                                className={`${activeIndex === 4 ? "active" : ""} line-circle`}
                            />
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} className="service">
                        <SCSmallSubtitle onClick={() => handleChangeClick(page.path, 'telecommunications')}
                                         color={activeIndex === 5 ? colors.secondary : ""}>
                            Telecommunications
                        </SCSmallSubtitle>
                        <div
                            className="clickableService"
                            onMouseEnter={() => handleHoverChange(5)}
                            onMouseLeave={() => handleHoverChange(null)}
                            onClick={() => handleChangeClick(page.path, 'telecommunications')}
                            onTouchEnd={(e) => handleChangeTouch(e, -1700, page.path)}
                        >
                            <SCSmall>
                                Aerial & Underground
                                <br/>
                                Integrated Projects
                                <br/>
                                Specialized Crossings
                            </SCSmall>
                            <div
                                className={`${activeIndex === 5 ? "active" : ""} line-circle`}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Visible xl lg>
                <div className="illustration"/>
            </Visible>
            <Visible md sm xs>
                <div ref={illustration}>
                    <IllustrationMobile transform={transform}/>
                </div>
            </Visible>
        </SCServices>
    );
};

export default Services;
