import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
  color?: any;
  strokeColor: any;
  align?:string;
};

export default styled.h2<propType>`
    display: block;
    ${typography.heading}
    color: ${(props: propType) => props.color || colors.primary};
    margin: 0;
    text-align: ${(props: propType) => props.align || 'auto'};

    span {
        display: inline-block;
        color: transparent;
        position: relative;
        -webkit-text-stroke: 2px  ${(props: propType) => props.strokeColor};
        -text-stroke: 2px ${(props: propType) => props.strokeColor};
        letter-spacing: 2px;
      
      @media ${device.sm}  {
        -webkit-text-stroke: 1px  ${(props: propType) => props.strokeColor};
        -text-stroke: 1px ${(props: propType) => props.strokeColor};
      }
    }

    @media ${device.md} {
        ${typography.heading.tablet}
    }

    @media ${device.sm}  {
        ${typography.heading.tablet}
    }

    @media ${device.xs}  {
        margin: 0 auto;
        ${typography.heading.mobile}
    }
`;
