// React requires
import React, {FC} from "react";

// React libraries
import {Container} from "react-grid-system";
import {motion} from "framer-motion";

// React custom configs
import routes from "../../routes";
import {routeTransitions, routeVariants} from "../../app/config/route-transitions";
import {CAREER_PAGE, INDUSTRIES_PAGE} from "../../app/consts/pages";
import {ABOUT_PAGE} from "../../app/consts/pages";

// React components
import Hero from "../../components/UI/Hero";
import Middle from "./Middle";
import Services from "./Services";
import {SCHome, HeroSmallText, HeroLargeText, SCSkyBackground, SCStrings, SCTransparentLogo} from "./SC-Home";

// React typography components
import SCHeading from "../../components/UI/typography/SC-Heading";
import SCSubheading from "../../components/UI/typography/SC-Subheading";

// React assets
import colors from "../../assets/styles/variables/colors";

const Home: FC = () => {
    const industries = routes.find((item) => item.name === INDUSTRIES_PAGE);
    const career = routes.find((item) => item.name === CAREER_PAGE);
    const about = routes.find((item) => item.name === ABOUT_PAGE);

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={routeVariants}
            transition={routeTransitions}
        >
            <SCHome>
                <SCSkyBackground>
                    <Hero>
                        <SCStrings/>
                        <Container>
                            <HeroLargeText>
                                <SCHeading
                                    className="heading"
                                    align="right"
                                    color={colors.hero.headingLight}
                                    strokeColor={colors.hero.headingLight}
                                >
                                    <span>Integrated</span> engineering
                                </SCHeading>
                            </HeroLargeText>
                            <HeroSmallText>
                                <SCSubheading className="subheading" color={colors.white}>
                                    Infrastructure design & coordination. <br/>
                                    One team of experts.
                                </SCSubheading>
                            </HeroSmallText>
                        </Container>
                        <SCTransparentLogo/>
                    </Hero>
                    <Services page={industries}/>
                </SCSkyBackground>
                <Middle page={{about: about, career: career}}/>
            </SCHome>
        </motion.div>
    );
};

export default Home;
