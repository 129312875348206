import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
  lineColor?: any;
  color?: any;
  textAlign?: string;
  weight?: string;
};

export default styled.h3<propType>`
  color: ${(props: propType) => props.color || colors.primary};
  font-weight: ${(props: propType) => props.weight || 300};
  ${typography.subheading};

  ${(props: propType) => 
    props.textAlign && `text-align: ${props.textAlign}` }
  
  
  ${(props: propType) =>
    props.lineColor &&
    `
  &:after {
    content: "";
    background-color: ${props.lineColor};
    width: 82px;
    height: 3px;
    display: block;
    margin: 12px auto 0;
    
      @media ${device.lg} {
        display: none;

      }
    }
    `};

  @media ${device.md} {
    ${typography.subheading.tablet}
  }

  @media ${device.sm} {
    ${typography.subheading.mobile}
  }

  @media ${device.xs} {
    text-align: left;
  }
`;
