import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import {device} from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
    color?: any;
    mobileInline?: any;
};

export default styled.div<propType>`
  ${typography.body_large};
  color: ${(props: propType) => props.color || colors.primary};
  margin: 0;

  @media ${device.lg} {
    ${typography.body_large.desktop};
    display: ${(props: propType) => props.mobileInline ? 'inline' : 'block'};
  }

  @media ${device.md} {
    ${typography.body_large.tablet};
  }

  @media ${device.sm} {
    ${typography.body_large.mobile};
  }
`;
